<template>
  <div class="chart-pane">
    <span class="close" @click="close">×</span>
    <div class="title">
      <span>监测变化曲线</span>
      <div style="display: flex; align-items: center; padding-right: 20px">
        <div class="granularity" v-if="type == 1">
          <span
            :class="[
              'granularity-item',
              switchType === 2 ? 'granularity-active' : '',
            ]"
            @click="() => cut(2)"
            >小时</span
          >
          <span
            :class="[
              'granularity-item',
              switchType === 3 ? 'granularity-active' : '',
            ]"
            @click="() => cut(3)"
            >天</span
          >
          <span
            :class="[
              'granularity-item',
              switchType === 4 ? 'granularity-active' : '',
            ]"
            @click="() => cut(4)"
            >月</span
          >
        </div>
        <DatePicker
          v-if="type == 2"
          v-model="time"
          type="daterange"
          :options="options"
          placement="bottom-end"
          style="width: 220px; margin-right: 20px"
          @on-change="() => getChartData(guids)"
        ></DatePicker>
        <span class="sel-term" v-if="type == 1" @click="termCut(2)"
          >自定义日期范围</span
        >
        <span class="sel-term" v-if="type == 2" @click="termCut(1)"
          >固定筛选条件</span
        >
        <Button type="primary" @click="exportData">导出</Button>
      </div>
    </div>
    <div class="accumulated-rainfall" v-if="flag">
      <span>积雨量：</span>
      <span>{{ rainfall.toFixed(2) }}mm</span>
    </div>
    <div class="chart" ref="sensorChart"></div>
    <div class="left-top-corner"></div>
    <div class="right-top-corner"></div>
    <div class="right-bottom-corner"></div>
    <div class="left-bottom-corner"></div>
  </div>
</template>

<script>
import moment from "moment";
import CU from "@/common/util";
import * as echarts from "echarts";
export default {
  name: "",
  props: {
    data: Object,
  },
  data() {
    return {
      sensors: [],
      guids: [],
      switchType: 3,
      type: 1,
      options: {
        disabledDate(date) {
          if (moment(date).isAfter(moment(new Date()).subtract(1, "days")))
            return true;
          return false;
        },
      },
      time: [],
      rainfall: 0,
      lineChartData: [],
      flag: false,
    };
  },
  methods: {
    close() {
      this.$emit("close-pane");
    },
    termCut(type) {
      if (this.type == type) return;
      this.type = type;
      this.time = [];
    },

    cut(type) {
      if (this.switchType === type) return;
      this.switchType = type;
      this.getChartData(this.guids);
    },
    getChartData(guids, key) {
      this.$post(this.$api.MONITOR.MONITOR_CHART_NEW, {
        guid: guids.join(","),
        pageSize:
          this.type == 2
            ? ""
            : this.switchType === 4
            ? 12
            : this.switchType === 2
            ? 24
            : 14,
        pageNo: this.type == 2 ? "" : 1,
        switchType:
          this.type == 2 && this.time[0] != "" ? "5" : this.switchType + "",
        sBeginTime:
          this.type == 1
            ? ""
            : !this.time[0]
            ? ""
            : moment(this.time[0]).format("YYYY-MM-DD"),
        sEndTime:
          this.type == 1
            ? ""
            : !this.time[1]
            ? ""
            : moment(this.time[1]).format("YYYY-MM-DD"),
        days:
          this.type == 1
            ? ""
            : moment(this.time[1]).diff(moment(this.time[0]), "days") + 1,
      }).then((res) => {
        this.rainfall = 0;
        res.forEach((item) => {
          if (item.name.indexOf("雨量") > -1) {
            this.flag = true;
            item.child.forEach((el) => {
              this.rainfall += +el.value;
            });
          }
        });
        this.lineChartData = res || [];
        this.initSoilLine(key);
      });
    },
    initSoilLine(key) {
      let xData = this.lineChartData[0].child.map(
        (el) =>
          `${this.switchType === 4 && el.showTime === 0 ? 12 : el.showTime} ${
            this.type == 2
              ? "日"
              : this.switchType === 2
              ? "时"
              : this.switchType === 3
              ? "日"
              : "月"
          }`
      );
      let myChart = echarts.init(this.$refs.sensorChart);
      let option = {
        grid: {
          top: 50,
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: this.lineChartData.map((item) => item.name),
          textStyle: {
            color: "#fff",
          },
        },
        xAxis: {
          type: "category",
          data: xData,
          axisLine: {
            lineStyle: {
              color: "#fbfbfb",
            },
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#fbfbfb",
            },
          },
        },
        color: [
          "#08DDDD",
          "#00ff19",
          "#c7ff00",
          "#ffb500",
          "#ff0000",
          "#ae00ff",
          "#0900ff",
          "#013f0d",
          "#2a7f81",
          "#66812a",
        ],
        series: this.lineChartData.map((item) => {
          let obj = {
            data: [],
            type: "line",
            smooth: true,
            name: item.name,
          };
          item.child.forEach((el) => {
            if (!el.value || el.value === "0") {
              obj.data.push("");
            } else {
              obj.data.push(el.value);
            }
          });
          return obj;
        }),
      };
      myChart.setOption(option);
    },
    exportData() {
      let columns = this.lineChartData.map(
        (item) => `${item.name}(${item.sensorCategoryName})`
      );
      columns.unshift("");
      let data = [];
      for (let i = 0; i < this.lineChartData[0].child.length; i++) {
        data[i] = [this.lineChartData[0].child[i].showAllTime];
        this.lineChartData.forEach((item) => {
          data[i].push(+item.child[i].value);
        });
      }
      data.push(["积雨量(mm)", this.rainfall]);
      /**
       * s:开始的单元格
       * e:结束的单元格
       * r:row, 表示行号，从0计
       * c:col, 表示列号，从0计
       */
      let merge = [
        {
          s: { r: data.length, c: 1 },
          e: { r: data.length, c: columns.length - 1 },
        },
      ];
      CU.exportExcel(columns, data, "数据监测", true, merge);
    },
  },
  mounted() {
    this.sensors = this.data.sensorList || [];
    this.guids = this.sensors.map((item) => item.guid);
    this.getChartData(this.guids);
  },
  watch: {
    data: {
      deep: true,
      handler(n) {
        this.sensors = n.sensorList || [];
        this.guids = this.sensors.map((item) => item.guid);
        this.getChartData(this.guids);
      },
    },
  },
};
</script>

<style lang="less" scoped>
.chart-pane {
  position: absolute;
  left: 382px;
  top: 130px;
  width: calc(100% - 864px);
  height: 400px;
  // background: rgba(23, 34, 60, 0.6);
  background: linear-gradient(
    270deg,
    rgba(14, 57, 51, 0.2) 0%,
    rgba(14, 57, 51, 0.478) 12%,
    rgba(14, 57, 51, 0.6) 90%,
    rgba(14, 57, 51, 0.8) 100%
  );
  color: #fff;
  z-index: 100001;
  min-width: 500px;
  padding: 16px;
  .close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    color: #e0e0e0;
    font-size: 20px;
    &:hover {
      color: #fff;
    }
  }
  .title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .granularity {
      margin-right: 20px;
      .granularity-item {
        padding: 4px 12px;
        margin-left: 10px;
        border-radius: 4px;
        border: 1px solid #fff;
        cursor: pointer;
      }
      .granularity-active {
        color: #06baa1;
        border-color: #06baa1;
      }
    }
    .sel-term {
      color: #06baa1;
      cursor: pointer;
      margin-right: 10px;
    }
  }
  .accumulated-rainfall {
    width: 100%;
    text-align: right;
  }
  .chart {
    height: 320px;
  }
}
/deep/ .ivu-picker-panel-icon-btn {
  color: #333;
}
/deep/ .ivu-date-picker-header-label {
  color: #333;
}
/deep/ .ivu-date-picker-cells-header span {
  color: #333;
}
/deep/ .ivu-date-picker-cells span em {
  color: #333;
}
.left-top-corner {
  width: 16px;
  height: 16px;
  border-top: 2px solid #00fff7;
  border-left: 2px solid #00fff7;
  position: absolute;
  left: 0px;
  top: 0px;
}

.right-top-corner {
  width: 16px;
  height: 16px;
  border-top: 2px solid #00fff7;
  border-right: 2px solid #00fff7;
  position: absolute;
  right: 0px;
  top: 0px;
}

.right-bottom-corner {
  width: 16px;
  height: 16px;
  border-bottom: 2px solid #00fff7;
  border-right: 2px solid #00fff7;
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.left-bottom-corner {
  width: 16px;
  height: 16px;
  border-bottom: 2px solid #00fff7;
  border-left: 2px solid #00fff7;
  position: absolute;
  left: 0px;
  bottom: 0px;
}
</style>